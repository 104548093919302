<div class='card-page'>
  <PageHeader>
    <div class='row c'>
      <Breadcrumbs :items='[{title:"Киборг"},{title: "Инструмент - Оферта"}]' />
    </div>
    <PageTitle :title='`Инструмент - Оферта`' />
  </PageHeader>

  <Tabs :columns='columns' :data='content' headerClass="tabs-header_borders-top-bottom">
  </Tabs>

  <div class="card--list">
    <div class="card--wrap">
      <div class="card--item general">
        <div>
          <div class="card--badge">
            <div class="card--badge-react">
              Огонь
            </div>
            <div class="card--badge-stage">
              В работе
            </div>
          </div>
          <div class="card--header">
            <img src="https://via.placeholder.com/48x48" alt="">
            <div>
              <div class="card--header-name">
                Попов Иван Олегович
              </div>
              <div class="card--header-description">
                Директор филиальной сети
              </div>
            </div>
          </div>
          <div class="card--description">
            <div class="card--title">
              Вопрос подбор плуга по типу почвы
            </div>
            <div class="card--text">
              Есть подозрения что этот вопрос не помогает в выборе, клиенты постоянно тупят на нем. Предлагаю его
              удалить,
              ну или как минимум — убрать из важных!
            </div>
          </div>
        </div>
        <div class="card--info">
          <div class="card--info-date">
            30 сентября 2021
          </div>
          <div class="card--info-status">
            <Vote :item="{id: 1}" :vote="{disabled: false, like: 96, dislike: 3, views: 10}"></Vote>
          </div>
        </div>
      </div>
    </div>
    <div class="card--wrap">
      <div class="card--item">
        <div>
          <div class="card--badge">
            <div class="card--badge-react">
              Без восторга
            </div>
            <div class="card--badge-stage">
              На рассмотрении
            </div>
          </div>
          <div class="card--header">
            <img src="https://via.placeholder.com/48x48" alt="">
            <div>
              <div class="card--header-name">
                Попов Иван Олегович
              </div>
              <div class="card--header-description">
                Директор филиальной сети
              </div>
            </div>
          </div>
          <div class="card--description">
            <div class="card--title">
              Не создается контрагент, перепробовал все способы.
            </div>
            <div class="card--text">
              Не загрузилась страница контрагентов, создал в 1с
            </div>
          </div>
        </div>
        <div class="card--info">
          <div class="card--info-date">
            24 сентября 2021
          </div>
          <div class="card--info-status">
            <Vote :item="{id: 2}" :vote="{disabled: false, like: 24, dislike: 12, views: 5}"></Vote>
          </div>
        </div>
      </div>
    </div>
    <div class="card--wrap">
      <div class="card--item">
        <div>
          <div class="card--badge">
            <div class="card--badge-react">
              Без восторга
            </div>
            <div class="card--badge-stage">
              В работе
            </div>
          </div>
          <div class="card--header">
            <img src="https://via.placeholder.com/48x48" alt="">
            <div>
              <div class="card--header-name">
                Рахмет Махотдино
              </div>
              <div class="card--header-description">
                Директор филиальной сети
              </div>
            </div>
          </div>
          <div class="card--description">
            <div class="card--title">
              Пропала кнопка отправить оферту
            </div>
            <div class="card--text">
              После последнего обновления опросника пропала кнопка отправить оферту, подскажите, какие изменения внесены
              в
              новом опроснике и как мне отправлять оферты сейчас?
            </div>
          </div>
        </div>
        <div class="card--info">
          <div class="card--info-date">
            16 мая 2021
          </div>
          <div class="card--info-status">
            <Vote :item="{id: 3}" :vote="{disabled: false, like: 12, dislike: 3, views: 10}"></Vote>
          </div>
        </div>
      </div>
    </div>
    <div class="card--wrap">
      <div class="card--item">
        <div>
          <div class="card--badge">
            <div class="card--badge-react">
              Без восторга
            </div>
            <div class="card--badge-stage">
              В работе
            </div>
          </div>
          <div class="card--header">
            <img src="https://via.placeholder.com/48x48" alt="">
            <div>
              <div class="card--header-name">
                Попов Иван Олегович
              </div>
              <div class="card--header-description">
                Директор филиальной сети
              </div>
            </div>
          </div>
          <div class="card--description">
            <div class="card--title">
              Вопрос подбор плуга по типу почвы
            </div>
            <div class="card--text">
              Есть подозрения что этот вопрос не помогает в выборе, клиенты постоянно тупят на нем. Предлагаю его
              удалить,
              ну или как минимум — убрать из важных!
            </div>
          </div>
        </div>
        <div class="card--info">
          <div class="card--info-date">
            16 мая 2020
          </div>
          <div class="card--info-status">
            <Vote :item="{id: 4}" :vote="{disabled: false, like: 9, dislike: 3, views: 5}"></Vote>
          </div>
        </div>
      </div>
    </div>
    <div class="card--wrap">
      <div class="card--item">
        <div>
          <div class="card--badge">
            <div class="card--badge-react">
              Без восторга
            </div>
            <div class="card--badge-stage">
              На рассмотрении
            </div>
          </div>
          <div class="card--header">
            <img src="https://via.placeholder.com/48x48" alt="">
            <div>
              <div class="card--header-name">
                Попов Иван Олегович
              </div>
              <div class="card--header-description">
                Директор филиальной сети
              </div>
            </div>
          </div>
          <div class="card--description">
            <div class="card--title">
              Не создается контрагент, перепробовал все способы..
            </div>
            <div class="card--text">
              Не загрузилась страница контрагентов, создал в 1с
            </div>
          </div>
        </div>
        <div class="card--info">
          <div class="card--info-date">
            16 мая 2020
          </div>
          <div class="card--info-status">
            <Vote :item="{id: 4}" :vote="{disabled: false, like: 9, dislike: 3, views: 10}"></Vote>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
