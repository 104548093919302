<template src="./templates/cyborg-card-page.html"></template>

<script>
import Tabs from '@c/Tabs';
import Vote from '@c/Vote';
import FormButton from '@f/Button';
import PageTitle from '../../components/PageTitle';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageHeader from '../../components/Header';

export default {
    name: 'CyborgCardPage',
    components: {
        PageTitle,
        PageHeader,
        Breadcrumbs,
        FormButton,
        Tabs,
        Vote
    },
    data() {
        return {
            columns: [
                {
                    id: 'description',
                    label: 'Описание'
                },
                {
                    id: 'descriptionTech',
                    label: 'Техническое описание'
                },
                {
                    id: 'offer',
                    label: 'Предложения'
                },
                {
                    id: 'history',
                    label: 'История изменений'
                },
                {
                    id: 'suggestions',
                    label: 'Мои предложения'
                }
            ],
            content: {
                description: {
                    content: `<div>
                                <p class='mb-2'>
                                  <span class='tabs-content--title'>Определение:</span> Оферта – это коммерческое предложение.
                                </p>
                                <p class='mb-2'>
                                  <span class='tabs-content--title'>Ответственный за инструмент:</span> Иванов Иван Петрович
                                </p>
                                <p class='mb-2'>
                                  <span class='tabs-content--title'>Рабочая группа:</span> Сидоров Евгений Евгеньевич (разработчик) Петров Иван Петрович (дизайнер)
                                </p>
                              </div>
                              <div class='card-page--stages'>
                                <div class='h4 card-page--stages-title'>
                                  Этапы создания оферты:
                                </div>

                                <div class='card-page--item'>
                                  <div class='card-page--item-title'>
                                    1. Зайти на https://crm.lbr.ru/ и выбрать во вкладке контрагентов клиента, для которого подбирается техника
                                  </div>
                                  <img src='https://via.placeholder.com/362x166' alt=''>
                                </div>

                                <div class='card-page--item'>
                                  <div>
                                    <div class='card-page--item-title'>
                                      2. Провести опрос. Для умного подбора необходимо ответить минимум на 4 вопроса. Цены на технику станут доступны только после выбора способа финансирования
                                    </div>
                                    <div class='card-page--item-title'>
                                      3. После подбора техники ее можно отправить...
                                    </div>
                                  </div>
                                  <img src='https://via.placeholder.com/362x166' alt=''>
                                </div>

                                <div class='card-page--item'>
                                  <div>
                                    <div class='card-page--item-title'>
                                      4. Готовую оферту можно редактировать: добавлять фотографии и текст выгод...
                                    </div>
                                    <div class='card-page--item-title'>
                                      5. Сам клиент также может редактировать...
                                    </div>
                                  </div>
                                  <img src='https://via.placeholder.com/362x166' alt=''>
                                </div>
                              </div>`
                },
                descriptionTech: {
                    content: `<div>
                                <p class='mb-2'>
                                  Техническое описание
                                </p>
                              </div>`
                },
                offer: {
                    content: ''
                },
                history: {
                    content: `<div>
                                <p class='mb-2'>
                                  История изменений
                                </p>
                              </div>`
                },
                suggestions: {
                    content: `<div>
                                <p class='mb-2'>
                                  Мои предложения
                                </p>
                              </div>`
                }
            }
        };
    }
};
</script>

<style lang="scss">
.card-page {
    .h1 {
        margin-bottom: 24px !important;
    }

    .tabs {
        &.container {
            padding: 0;
        }

        &-header {
            padding: 10px 40px !important;
            border-bottom: 1px solid #d9dee6;
            border-top: 1px solid #d9dee6;
        }

        &-content {
            padding: 0 !important;
            margin: 24px 40px !important;
        }
    }
    .tabs-content {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: #37352f;
    }

    &--stages-title {
        margin-bottom: 24px;
    }

    &--item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 24px;

        img {
            margin-left: 20px;
        }
    }

    &--item-title {
        font-size: 14px;
        line-height: 140%;
        font-weight: normal;
        color: #37352f;
        margin-bottom: 16px;
    }
}

.card {
    &--list {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        padding: 0 30px;
    }

    &--wrap {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;
        flex: 0 25%;

        width: 100%;
        padding: 10px;
    }

    &--item {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;

        border: 1px solid rgba(55, 53, 47, 0.16);
        border-radius: 3px;

        height: 100%;
        padding: 24px;

        &.general {
            border-color: #e9a800;

            .card--badge-react {
                background-color: #eb5757;
                color: #ffffff;
            }
        }
    }

    &--badge {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 8px;
    }

    &--badge-react {
        border-radius: 4px;
        padding: 4px 8px;

        background-color: #f7f6f3;
        color: #37352f;
        white-space: nowrap;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
    }

    &--badge-stage {
        color: rgba(0, 0, 0, 0.6);
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 140%;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        margin-bottom: 16px;

        img {
            margin-right: 8px;
            border-radius: 50%;
        }
    }

    &--header-name {
        color: #37352f;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
    }

    &--header-description {
        color: #37352f;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 140%;
    }

    &--title {
        margin-bottom: 8px;

        color: #37352f;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
    }

    &--text {
        margin-bottom: 16px;

        color: #37352f;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 140%;
    }

    &--info-date {
        margin-bottom: 24px;

        color: #37352f;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 140%;
    }

    &--info-status {
    }
}

.tabs-content {
    &--title {
        font-weight: bold;
    }
}
</style>
